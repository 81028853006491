/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");

/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
  font-size: 13px;
  line-height: 20px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  color: #4f5457;
  text-decoration: none;
}

strong {
  font-weight: 500;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  clip-path: none;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

body {
  background: #f4f8f9;
  color: #4f5457;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.ui-menu,
.ui-menu .ui-menu-parent .ui-menu-child {
  width: auto;
}

.ui-menu .ui-menuitem .ui-menuitem-link:hover,
.ui-menu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background: #f5fcfe;
  color: #4f5457;
}

.ui-menu .ui-menuitem:first-child,
.ui-menu-child > .ui-menuitem:first-child .ui-menuitem-link {
  padding-top: 5px;
}

.ui-menu .ui-menuitem:last-child,
.ui-menu-child > .ui-menuitem:last-child .ui-menuitem-link {
  padding-bottom: 5px;
}

.ui-menu .ui-menuitem:last-child .ui-menuitem-link:hover,
.ui-menu-child > .ui-menuitem:last-child .ui-menuitem-link:hover {
  border-radius: 0 0 5px 5px;
}

.ui-menu .ui-menuitem:first-child .ui-menuitem-link:hover,
.ui-menu-child > .ui-menuitem:first-child .ui-menuitem-link:hover {
  border-radius: 5px 5px 0 0;
}

.ui-contextmenu .ui-menu-separator,
.ui-tieredmenu .ui-menu-separator {
  border-top: 1px solid #e0e6e8;
  padding: 0 0 4px 0;
  margin-top: 4px !important;
}

.ui-menu .ui-menu-child {
  background: #ffffff;
  padding: 0 !important;
}

.ui-menu .ui-menuitem-text {
  display: inline-block;
}

.ui-menu .ui-menuitem-link.active {
  background: #f5fcfe;
}

.ui-menu .ui-menuitem-link .ui-menuitem-icon {
  margin-left: -28px;
  margin-right: 8px;
}

.ui-menu .ui-menuitem .ui-menuitem-link {
  color: #4f5457;
}

.ui-menu .ui-menu-parent .ui-submenu-icon {
  margin-right: -20px;
}

.fa-caret-right:before,
.pi-caret-right:before {
  content: "";
  float: right;
  width: 10px;
  height: 15px;
  background: url("./assets/img/sprite.svg");
  background-position: -110px -1824px;
}

/*Context menu*/
.ui-contextmenu {
  background: #ffffff !important;
  padding: 0 !important;
  width: auto;
}
.ui-tieredmenu .ui-menuitem .ui-menuitem-text {
  margin-left: calc(0.25em + 16px);
}

.ui-tieredmenu .ui-menuitem-icon {
  margin-right: 0;
}
.ui-contextmenu li,
.ui-tieredmenu li {
  margin: 0 !important;
}

.ui-contextmenu li a,
.ui-tieredmenu li a {
  /* padding: 2px 30px 2px 40px!important; */
  border-radius: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui-contextmenu .ui-menuitem .ui-menuitem-text {
  color: #4f5457;
}

.ui-contextmenu .ui-menuitem .ui-menuitem-text {
  margin-left: calc(0.25em + 16px);
}

.ui-contextmenu .ui-menuitem-icon {
  margin-right: 0;
  position: relative;
  left: -2px;
}

/* c8 disable mamrgin after icons */
/*
.ui-menuitem-icon.tick:before {
  margin-right: calc(-0.25em - 20px);
  margin-left: -2px;
}
*/
.ui-contextmenu .ui-menuitem-icon + .ui-menuitem-text {
  margin-left: 0;
}

.ui-contextmenu .ui-menu-list li.ui-menuitem:first-child {
  padding-top: 2px;
}

/* .ui-contextmenu .ui-menu-list li.ui-menuitem .ui-menuitem-link:hover {
  background: #F5FCFE;
  color: #4F5457;
} */

.ui-widget.ui-widget-content li.ui-menuitem a.ui-menuitem-link {
  color: #4f5457;
}

.ui-widget.ui-widget-content li.ui-menuitem a.ui-menuitem-link:hover {
  background: #f5fcfe;
}

app-settings
  .ui-widget.ui-widget-content
  li.ui-menuitem
  a.ui-menuitem-link:hover {
  background: transparent;
}

.ui-contextmenu
  .ui-menu-list
  li.ui-menuitem:first-child
  .ui-menuitem-link:hover {
  border-radius: 5px 5px 0 0;
}

.ui-contextmenu
  .ui-menu-list
  li.ui-menuitem:last-child
  .ui-menuitem-link:hover {
  border-radius: 0 0 5px 5px;
}

.ui-contextmenu.ui-widget.ui-widget-content
  .ui-menu-list
  li.ng-star-inserted.ui-menuitem
  a.ui-menuitem-link.ng-star-inserted {
  padding: 0.25em 30px 0.25em 10px;
  border-radius: 5px;
}

.ui-contextmenu .ui-menuitem-link .ui-submenu-icon {
  position: absolute;
  margin-top: -0.5em;
  right: 12px;
  top: 46%;
}

.srch.ui-menuitem.ui-widget a.ui-menuitem-link.ng-star-inserted {
  line-height: 1em;
}

.tick:before {
  content: "";
  float: left;
  width: 20px;
  height: 14px;
  background: url("./assets/img/sprite.svg");
  background-position: -490px -1968px;
  margin-top: 2px;
}

.ui-inputtext.ui-state-focus,
.ui-inputtext:focus {
  box-shadow: none;
}

.ui-widget-header .ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-state-highlight {
  /* background: #D8F1FF; */
  color: #4f5457;
  background-color: transparent !important;
}

/*checkbox and radios*/
.ui-radiobutton-box {
  width: 11px;
  height: 11px;
  line-height: 11px;
}

.ui-chkbox-box.ui-state-active,
.ui-radiobutton-box.ui-state-active {
  border: none;
  background: #ffffff;
}

.ui-radiobutton-icon.fa-circle:before,
.ui-radiobutton-icon.pi-circle-on:before {
  content: "";
  float: left;
  width: 12px;
  height: 12px;
  background: url(./assets/img/sprite.svg);
  background-position: -17px -1967px;
}

/* .ui-chkbox{margin-top: -5px;} */
/* .ui-chkbox-label{vertical-align: initial;} */
.ui-chkbox .ui-chkbox-box {
  border: 0;
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.ui-chkbox .ui-chkbox-box.ui-state-focus.ui-state-active {
  background: transparent;
}

.ui-chkbox .ui-chkbox-icon {
  float: left;
  width: 22px;
  height: 22px;
  background: url(./assets/img/sprite.svg);
  background-position: -208px -1963px;
}

.fa-check:before,
.pi-check:before {
  display: none;
}

.ui-chkbox .ui-chkbox-icon.fa-check,
.ui-chkbox .ui-chkbox-icon.pi-check {
  background-position: -296px -1963px;
}

.ui-chkbox .ui-chkbox-box.ui-state-focus,
.ui-radiobutton-box.ui-state-focus {
  box-shadow: none;
}

/*Tooltip*/
.ui-tooltip .ui-tooltip-text {
  border-radius: 15px;
  font-size: 12px;
  padding: 0px 10px;
  line-height: 20px;
  /* height: 20px; */
  /* white-space: nowrap; */
  height: auto;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-tooltip-right .ui-tooltip-arrow {
  left: 1px;
}

.text-center {
  text-align: center;
}

/* C8 addons */
button {
  display: inline-block;
  padding: 10px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  position: relative;
  background: transparent;
  white-space: nowrap;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

@import "styles/mixins";
